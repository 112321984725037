.homepage_content{
    text-align: -webkit-center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.homepage_afa{
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    max-width:1105px;
    font-family: 'Neuton', serif;
}

.homepage_vision{
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    max-width:1105px;
    font-family: 'Neuton', serif;
}

.homepage_objective{
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    max-width: 1263px;
    margin-top: 4rem;
    font-family: 'Neuton', serif;
}

@media only screen and (max-width: 768px){
    .homepage_content{
        margin-top: 3rem;
    }

    .homepage_afa{
        color: #000;
        text-align: center;
        font-family: 'Neuton', serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 125% */
        letter-spacing: 0.16px;
        padding: 0rem 1rem;
    }

    .homepage_vision{
        color: #000;
        text-align: center;
        font-family: 'Neuton', serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 125% */
        letter-spacing: 0.16px;
        padding: 0rem 1rem;
    }

    .homepage_objective{
        color: #000;
        text-align: center;
        font-family: 'Neuton', serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 125% */
        letter-spacing: 0.16px;
        margin-top: 2rem;
        padding: 0rem 1rem;
    }
}