.contactus_title{
    color: #FFD900;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 7rem;
}

.contactus_container{
    background: #FFF;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    text-align: center;
    width: max-content;
    padding: 5rem;
    margin: 0 auto;
}

.insta_contact{
    width: 22px;
}

.contact_phone{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 80% */
    letter-spacing: 0.8px;
    margin-bottom: 3rem;
}

.contact_whatsapp{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 80% */
    letter-spacing: 0.8px;
    margin-bottom: 3rem;
}

.contact_mail{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 80% */
    letter-spacing: 0.8px;
    margin-bottom: 3rem;
    cursor: pointer;
}

.contact_socialmedia{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.48px;
}

.contact_icons{
    transform: scale(1.8);
    margin-top: 0.5rem;
    margin-right: 2rem;
}

.contatuspage_container{
    margin-bottom: 10rem;
}

/* Mediq queries */
@media only screen and (max-width: 768px) {
    .contactus_title{
        color: #FFD900;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 2rem;
    }

    .contact_phone{
        color: #000;
        font-size: 14.48px;
        font-style: normal;
        font-weight: 300;
        line-height: 11.584px; /* 80% */
        letter-spacing: 0.29px;
    }

    .contactus_container{
        padding: 0.5rem;
    }

    .contact_whatsapp{
        color: #000;
        font-size: 14.48px;
        font-style: normal;
        font-weight: 300;
        line-height: 11.584px; /* 80% */
        letter-spacing: 0.29px;
    }

    .contact_mail{
        color: #000;
        font-size: 14.48px;
        font-style: normal;
        font-weight: 300;
        line-height: 11.584px; /* 80% */
        letter-spacing: 0.29px;
    }

    .contact_socialmedia{
        color: #000;
        font-size: 8.688px;
        font-style: normal;
        font-weight: 300;
        line-height: 11.584px; /* 133.333% */
        letter-spacing: 0.174px;
    }

    .contact_icons{
        transform: scale(1);
        margin-top: 0rem;
        margin-right: 1rem;
    }
}