
.values_container{
    max-width: 770px;
    margin: 0 auto;
    margin-bottom: 6rem;
}

.values_absolute_container{
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
}

.values_desc_sec{
    text-align: center;
    background: #FBFBFB;
    max-width: 770px;
    margin-top: 10rem;
    padding: 1rem 2rem;;
}

.values_title{
    color: #FFD900;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background-color: white !important;
    margin: 0px;
    margin-top: 20px;
}

.values_subtitle{
    color: #FFD900;
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height:normal;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.values_description{
    color: #000;
    text-align: justify;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 137.5% */
    letter-spacing: 0.48px;
}

.value_letter{
    color: #FFD900;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: capitalize;
    background-color: #FBFBFB;
    border-radius: 50px;
    padding: 0px 1rem;
    margin-right: 10rem;
}

.letter_desc{
    color: #FFF;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    }

    @media only screen and (max-width: 768px) {
        .values_title{
            color: #FFD900;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 1rem;
        }

        .values_desc_sec{
            margin-top: 0px;
        }

        .values_subtitle{
            color: #FFD900;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.8px;
            text-transform: capitalize;
        }

        .values_description{
            color: #000;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 125% */
            letter-spacing: 0.16px;
        }
    }