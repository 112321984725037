/* .football_banner{
    position: relative;
    margin-bottom: 60rem;
    margin-left: auto;
    margin-right:auto;
} */

.football_container{
    text-align: center;
    background: #FBFBFB;
    max-width: 770px;
    margin: 0 auto;
    margin-bottom: 6rem;
}

.football_title{
    color: #FFD900;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    position: absolute;
    bottom: 0px;
    background-color: white !important;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0.5rem 0rem;
    /* width: 100vw-50%; */
    width: calc(100vw - 40%);
    text-align: center;
}

.football_paragraph{
    color: #000;
    text-align: justify;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 137.5% */
    letter-spacing: 0.48px;
}

@media only screen and (max-width: 768px) {
    .football_title{
        color: #FFD900;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0rem;
        width: calc(100vw - 20%);
    }

    .football_paragraph{
        color: #000;
        text-align: justify;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 125% */
        letter-spacing: 0.16px;
        padding: 0 1rem;
    }
}