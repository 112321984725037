.table_header{
    background: #000;
    color: #FFF;
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 2rem;
    margin-top: 3rem;
    }

.table_container{
    padding: 0rem 5rem;
    margin-top: 5rem;
    margin-bottom: 10rem;
}

.table_row{
    background: #F2F2F2;
    padding: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

/* media queries */

@media only screen and (max-width: 768px) {
    .table_container{
        padding: 0rem 10px;
    }   
}