
.kidsacademy_container{
    text-align: center;
    background: #FBFBFB;
    margin: 0 auto;
}

.kidsacademy_title{
    color: #FFD900;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    bottom: 0px;
    background-color: white !important;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0.5rem 0rem;
    /* width: 100vw-50%; */
    width: calc(100vw - 40%);
    text-align: center;
}

.kidsacademy_Desc{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.48px;
    text-align: left;
    max-width: 770px;
    margin: 0 auto;
}

.training_plan_title{
    color: #000;
    text-align: center;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.48px;
}

.training_hours{
    color: #000;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0.48px;
    text-align: center;
}

.academy_table_header{
    background: #000;
    color: #FFF;
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 2rem;
    margin-top: 3rem;
    }

.academy_table_row{
    background: #F2F2F2;
    padding: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.batch_title{
    color: #FFD900;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    text-align: center;
}

.batches{
    color: #000;
    text-align: center;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px; /* 208.333% */
    letter-spacing: 0.48px;
}

.locations_title{
    color: #FFD900;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.locations_link{
    color: #3382E7;
    text-align: center;
    font-family: Georgia;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px; /* 306.25% */
    letter-spacing: 0.32px;
    text-decoration-line: underline;
}

.yokohama_title{
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.teams_title{
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.teams{
    color: #000;
    text-align: center;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.48px;
}

.adult_teams_title{
    color: #FFD900;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

.futsal_team{
    color: #000;
    text-align: center;
    font-family: 'Neuton', serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.48px;
}

.batch_registration{
    color: #000;
    text-align: center;
    font-family: 'Neuton', serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 100% */
    letter-spacing: 0.64px;
}

.registartion_link{
    color: #3382E7;
    font-family: Gelasio;
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.64px;
    text-decoration-line: underline;
    }

    @media only screen and (max-width: 768px) {
        .academy_table_header{
            color: #FFF;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .kidsacademy_title{
            color: #FFD900;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .kidsacademy_Desc{
            color: #000;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 125% */
            letter-spacing: 0.16px;
            margin-bottom: 1rem;
            padding: 0rem 1rem;
        }

        .training_plan_title{
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            line-height: 20px; /* 125% */
            letter-spacing: 0.16px;
        }

        .training_hours{
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            line-height: 20px; /* 125% */
            letter-spacing: 0.16px;
        }

        .academy_table_row{
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .locations_link{
            color: #3382E7;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: 106%; /* 10.6px */
            text-decoration-line: underline;
        }

        .batch_title{
            color: #FFD900;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.8px;
            text-transform: capitalize;
        }

        .batches{
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px; /* 214.286% */
            letter-spacing: 0.28px;
        }

        .batch_registration{
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.28px;
        }

        .registartion_link{
            color: #3382E7;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.28px;
        }

        .yokohama_title{
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .teams_title{
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .teams{
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.14px;
        }

        .adult_teams_title{
            color: #FFD900;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.8px;
            text-transform: capitalize;
        }

        .futsal_team{
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.14px;
        }
    }